.Cities {
  width: 100vw;
  background-color: #e6f6fd;
  @include mf(sm) {
    height: 100vh; }
  .Container {
    height: 100%; }
  &-Body {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 30px; } }

.CitiesCard {
  height: 100%;
  padding: 15px 10px 30px 10px;
  background-color: #fff;
  border-radius: 6px;
  &-Container {
    padding-left: 25px; }
  &-Header {
    margin-bottom: 30px; }
  &-Logo {
    max-width: 125px;
    display: block; }
  &-Heading {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 900;
    color: $accent-color;
    text-align: center;
    @include mf(sm) {
      margin-bottom: 45px;
      padding-left: 25px;
      font-size: 24px;
      text-align: left; } }
  &-Content {
    height: calc(100% - 140px);
    overflow-y: scroll;
    @include mf(sm) {
      height: calc(100% - 185px);
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5; }
      &::-webkit-scrollbar {
        width: 8px;
        background-color: $main-color; }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: $main-color;
        &:hover {
          background-color: darken($main-color, 20%); } } } }
  &-Table {
    display: flex;
    flex-wrap: wrap; }
  &-Column {
    width: 100%;
    padding-right: 15px;
    padding-left: 25px;
    border: none;
    @include mf(sm) {
      width: 25%;
      border-right: 1px solid #c7dfe9; }
    &:last-child {
      border: none; } }
  &-Item {
    margin-bottom: 10px;
    display: block; }
  &-Link {
    font-size: 18px;
    color: #222222;
    text-decoration: none;
    &:hover {
      text-decoration: underline; } }
  &-NameSpaceHeader {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    color: #222222;
    display: block; } }
